.connect-container {
  background-color: rgb(204, 204, 204);
  padding: 25px 10px 65px 10px;
  margin: 0px 50px;
  border-radius: 10px;
}

.connect-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.connect-card {
  flex: 0 0 calc(15% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  border: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: 100px; 
  height: 100px; 
}

.connect-card:hover {
  transform: translateY(-3px);
}

.connect-card a {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.connect-card a img {
  width: 50px; 
  height: 50px; 
  margin-bottom: 8px;
}

.connect-card a p {
  text-align: center;
  margin: 0;
  font-weight: bold;
}

.connect-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-family: 'Bungee Spice', sans-serif;
}
@media (max-width: 768px) {
  .connect-card {
    flex: 0 0 calc(33.33% - 20px);
    padding: 15px;
    width: 80px;
    height: 80px;
  }

  .connect-card a img {
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }

  .connect-container {
    /* padding: 5px; */
    padding: 3px;
  }

  .connect-container h2{
    font-size: 1.5em;
  }
}