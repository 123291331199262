body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: #f3f3f3;
  color: #333;
}

.container {
  width: 90%;
  margin: 0px auto;
  background: White;
  padding-top: 20px;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:25px;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2) inset;
}

.project-heading {
  text-align: center;
  font-size: 2em;
  margin-bottom: 30px;
  padding-top:40px;
  font-family: 'Bungee Spice', sans-serif;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.project-card {
  margin: 12px;
  background: #fff;
  border-radius: 18px;
  overflow: hidden;
  transition: transform 0.3s ease, border-bottom 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid transparent; /* Preemptively include the border */
}

.project-card:hover {
  transform: translateY(-15px);
  border-bottom: 5px solid #333; /* Change color on hover */
  border-radius: 18px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.project-details {
  padding: 20px;
}

.project-details h4 {
  margin: 0;
  font-size: 1.1em;
  color: #333;
  text-align:center;
}

.project-details p {
  margin: 10px 0;
  color: #666;
}

.project-links {
  display: flex;
  justify-content: center; /* Center align the links horizontally */
  margin-top: 20px;
}

.project-links a {
  display: flex;
  align-items: center; /* Center align the content vertically */
  text-decoration: none;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  transition: transform 0.2s ease;
  margin: 0 10px;
}

.project-links a:hover {
  transform: translateY(-2px);
}

.github-link {
  background: #333;
}

.live-link {
  background: #47bfbf;
}

.project-hover-details {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-card:hover .project-hover-details {
  transform: translateY(0);
}

@media (max-width: 768px) {
.project-card{
  margin:3px;
}
}