.home-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 50px 0;
}

.home-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

.text-content {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.image-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.image-content img {
    max-width: 80%;
    border-radius: 50%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.image-content img:hover {
    transform: rotate(5deg) scale(1.05);
}

h1 {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 10px;
}

.names{
    font-family: 'Rubik Doodle Shadow', sans-serif; 
}

p {
    font-size: 1.2em;
    line-height: 1.5;
}

.auto-input {
    font-weight: bold;
}

.buttons {
    margin-top: 30px;
}

.buttons a {
    text-decoration: none;
}

.homebutton {
    padding: 15px 30px;
    margin: 10px;
    background: linear-gradient(#ff5733, #ff8c33);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.homebutton:hover {
    background-color: #e64a19;
    transform: translateY(-3px);
}

.social-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-button {
    font-size: 2em;
    margin: 0 10px;
    /* color: #333; */
    transition: color 0.3s ease;
}

.social-button:hover {
    transform: translateY(-3px);
}

.social-button.linkedin {
    color: #0077b5; /* LinkedIn color */
}

.social-button.github {
    color: #333; /* GitHub color */
}

.social-button.whatsapp{
    color: #25D366; /* WhatsApp color */
}

.social-button.twitter {
    color: #1DA1F2; /* Twitter color */
}

@media (max-width: 768px) {
    .home-content {
        flex-direction: column;
        padding: 0 20px;
        align-items: center; 
    }

    .text-content {
        padding: 20px 0;
    }

    .image-content {
        order: -1; /* Change the order to display image at the top */
        margin-top: 0px;
    }

    .text-content h1 {
        font-size: 2.5em;
    }

    .text-content p {
        font-size: 1em;
    }
}
