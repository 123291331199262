body {
  margin: 0;
  font-family: Arial, sans-serif;
}

footer {
  padding: 50px 0;
  text-align: center;
}

.footer-content{
  max-width: 800px;
  margin: 0 auto;
}

#contact {
  padding: 40px;
}

.footer-content p {
  margin: 0;
}

.footer-content .copy {
  margin-top: 30px;
  font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .footer-content {
    padding: 10px;
  }

  .footer-content .copy {
    font-size: 0.7rem;
  }
}
