.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.about-heading-container {
  text-align: center;
  margin-bottom: 20px;
}

.about-heading {
  font-size: 2em;
  font-family: 'Bungee Spice', sans-serif;
}

.edu_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.edu_content_1 {
  flex: 1;
  margin-right: 20px;
  max-width: 600px;
}

.navigation-links {
  display: flex;
  align-items: center;
}

.nav-link {
  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 2px solid red;
  font-size: 1.4em;
  font-family: "Comic Sans MS", cursive;

}

.nav-link.active-link {
  font-weight: bold;
  color: red;
}

.logo-container {
  flex-shrink: 0;
  margin: auto 0px;
}

.logo {
  width: 350px;
  height: auto;
}

.justify-paragraph {
  text-align: justify;
}

@media (max-width: 1050px) {
  .logo-container {
    display: none;
  }

  .edu_content {
    flex-direction: column;
    align-items: center;
  }

  .edu_content_1 {
    margin-right: 0;
  }

  .navigation-links {
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    font-size: 0.9em;
  }

  .nav-link {
    margin: 0 10px;
  }

  .logo-container {
    margin-top: 20px;
  }

  .logo {
    width: 140px;
  }

  .institution {
    flex-direction: column;
  }

  .tab-contents {
    width: 70vw;
    margin: auto;
  }

  .names1,
  .year,
  .specializations {
    padding: 0px;
    font-size: 0.9em;
  }

  .about-description ,
  .about-details {
    font-size:1.0em;
  }

  .nav-link{
    font-size:1.2em;
  }
}

/* Education */
.tab-contents {
  padding: 0px;
  margin: 0px;
}

.institution {
  display: flex;
  flex-direction: row;
  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
}

.names1 {
  padding: 3px;
  color: red;
  font-weight: bold;
}

.year {
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.05);
  margin-left: auto;
  padding: 3px;
  color: #4caf50;
  font-weight: bold;
}

.specializations {
  margin-top: 0;
  padding: 0px 0px 0px 20px;
}

@media (max-width: 380px){
  .names1,
  .year,
  .specializations {
    padding: 0px;
    font-size: 0.7em;
  }
  .nav-link{
    font-size: 1.1em
  }
}
