.blogs0{
  padding: 110px 0px 120px 0px;
}

.blogs{
  margin: 0px 50px 50px 50px;
  padding-top:55px;
}

.blogs h2 {
  text-align: center;
  font-family: 'Bungee Spice', sans-serif;
  font-size: 2em;
}

.blog-container {
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  margin: 0px auto;
  border: 1px solid black;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding:45px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  transition: box-shadow 0.3s ease;
}

.blog-container:hover {
  box-shadow: 8px 8px 8px 8px rgba(0, 167, 142, 0.9);
}

.blog-section {
  display: flex;
}

.blog-card {
  width: 300px;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
}

.blog-content {
  padding: 10px;
}

.blog-content h3 {
  font-size: 1.2em;
  margin: 0;
  text-align:center;
  margin:10px;
}

.blog-content img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ccc;
}

.blog-content p {
  margin:5px 0px 5px 0px;
  margin: 5px 0;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 60px;
  line-height: 20px;
  white-space: normal;
  cursor: pointer;
}

.scroll-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.scroll-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  background: #333;
  color: #fff;
  border: none;
}

@media (max-width: 768px) {
  .blogs{
    margin:80px 0px;
  }
  .blog-container {
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    padding:25px 0px 25px 0px;
  }

  .blog-card {
    width: 80%;
  }
}
.blogs h5 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.see-all {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  background-color: rgb(0, 167, 142);
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.see-all:hover {
  background-color: #45a049;
}