.skills-section {
  padding: 20px;
  margin: 0px auto;
  margin-bottom:50px;
  max-width: 1300px;
}

.skills-heading {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  padding-top:40px;
  padding-bottom:40px;
  font-family: 'Bungee Spice', sans-serif;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-card {
  border-radius: 8px;
  padding: 5px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: #fff;
  width: 120px;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 10px rgba(0, 167, 142, 0.5);
}

.skill-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.skill-name {
  font-size: 14px;
  color: #333;
  transition: color 0.3s ease-in-out;
}

.skill-card:hover .skill-name {
  color: #ff4500;
}

.skill-card:hover .skill-logo {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .skills-section {
    padding: 10px;
  }

  .skill-card {
    width: 100px;
    padding: 1px;
    margin: 8px;
  }

  .skill-logo {
    width: 60px;
    height: 60px;
    margin-bottom: 6px;
  }

  .skill-name {
    font-size: 12px;
  }
}
