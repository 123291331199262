.ProgressBar {
    position: fixed;
    top: 0em;
    left: 0;
    height: 5px;
    z-index: 9999;
    background-color: white;
    width: 100%;
    border-radius: 0.5em;
  }
  
  .ProgressBar__progress {
    border-radius: 0 0.5em 0.5em 0;
    /* border: 1px solid black; */
    height: 5px;
    background-color: #cf2200;
    transition: width 0.2s ease-in-out;
  }
  
  
  