.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 4px 6px -6px #000;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.brand {
  font-size: 24px;
  color: red;
  font-weight: bold;
}

.menu {
  display: flex;
  align-items: center;
}

.menu .links {
  font-size: 20px;
  text-decoration: none;
  margin: 0 25px;
  font-weight: bold;
  transition: all 0.3s ease;
  color: inherit;
  position: relative;
  transition: border-bottom 0.3s ease;
  cursor: pointer; /* Added cursor property */
}

.menu .links:hover {
  color: #FF6347; /* Change the text color on hover */
}

.menu .links:first-child {
  margin-left: 0;
}

.menu .links:last-child {
  margin-right: 0;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  color: black;
}

@media (max-width: 990px) {
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: inherit;
    width: 250px;
    box-shadow: 0 4px 6px -6px #000;
    padding-bottom: 10px;
  }

  .menu.show {
    display: flex;
    animation: slideIn 0.5s forwards;
  }

  @keyframes slideIn {
    from {
      width: 0;
      opacity: 0;
    }
    to {
      width: 10em;
      opacity: 1;
    }
  }

  .menu .links {
    margin: 10px;
  }

  .mobile-menu-icon {
    display: block;
  }

  .dark-mode-toggle {
    margin-left: 0px;
  }
}

.dark-mode-toggle {
  margin-left: 10px;
  cursor: pointer;
}

.menu .links.active {
  border-bottom: 2px solid #FF6347; /* Tomato */
  color: #FF6347; /* Set the text color to match the border color */
}

.menu .links.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff5733;
}
.menu .links:hover::after {
  width: 100%;
}
