/* ScrollToTop.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensure it's on top of other elements */
    opacity: 0.1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
  }
  
  .scroll-to-top a {
    display: block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .scroll-to-top a:hover {
    background-color: #0056b3;
  }
  